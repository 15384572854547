import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1 } from '../components/styles'
import Layout from "../components/layout"
import Group from "../components/group"


export const pageQuery = graphql`
 query {
    allStrapiStoriesDetails(sort: { fields: [created_at], order: DESC}){
        edges {
         node {
            created_at
            seo {
                shareImage {
                    localFile {
                        childImageSharp {
                          gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
              }
           title
           date
           tooltip
           hot
           recommend
           stories_category {
               title
           }
           link {
            url
           }
         }
       }
      }
  allStrapiStories{
    edges {
     node {
        seo {
         metaTitle
         metaDescription
         shareImage {
             url
         }
       }
       title
       stories_categories {
         title
         link {
            url
         }
       }
     }
   }
  }
 }`

export default function StoriesPage({location,data}) {
    const node = data.allStrapiStories.edges[0].node
    const storiesArr = data.allStrapiStoriesDetails.edges
    const refList = useRef(null)
    const showMore = (e)=>{
        e.preventDefault()
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 14
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("opacity-30")
            e.target.classList.add("cursor-default")
        }
    }
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
                <h1 className="text-[200px] leading-none pb-[95px] lg:pb-10 pt-[260px] lg:pt-[165px] xl:text-[150px] lg:text-[72px]">{node.title}</h1>
                <div>
                    <ul className="text-[28px] sm:text-[22px] pb-[54px] font-bold text-[#bbb] flex items-center flex-wrap lg:flex-col lg:items-start">
                        <li><a href="/work" className="hover:text-black text-black">All</a></li>
                        {
                            node.stories_categories.map((n,i)=>{
                                return <li key={i} className="ml-8 lg:ml-0"><a href={n.link.url} className="hover:text-black">{n.title}</a></li>
                            })
                        }
                    </ul>
                    <div className="grid grid-cols-4 gap-x-8 mb-[95px] lg:mb-0 stroies-block lg:!grid-cols-1">
                    {
                        storiesArr.map(({node},i)=>{
                            if(node.hot && i<12){
                                if(i){
                                    return (
                                        <Group cl={i>node.length-1 || i>9?'lg:pb-14':'pb-14'} lg={true} key={i} node={node}/>
                                    )
                                }else{
                                    return (
                                        <div key={i} className="w-full overflow-hidden text-xl relative cursor-pointer lg:!relative lg:!top-0 lg:!col-span-1 lg:!h-[calc(60vh)]" onClick={()=>window.location.href=node.link.url}>
                                            <figure className="mb-2">
                                                <GatsbyImage className="w-full h-full bg-light" image={getImage(node.seo?.shareImage?.localFile)} alt="banner"/>
                                            </figure>
                                            <div className="absolute bottom-0 px-8 group">
                                                <p className="pb-4">{node.stories_category.title} – {node.date}</p>
                                                <p className="text-[72px] leading-none truncate-2-lines mb-8 xl:text-[56px] lg:text-[32px]">{node.title}</p>
                                                <a className="bg-white group-hover:bg-black group-hover:text-white w-[164px] h-[58px] text-black flex items-center justify-center" href={node.link.url}>Read more</a>
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    </div>
                    <div className="grid grid-cols-4 gap-x-8 gap-y-14 mb-[95px] lg:mb-10 lg:grid-cols-2 sm:grid-cols-1" ref={refList}>
                    {
                        storiesArr.map(({node},i)=>{
                            if(node.hot && i>11){
                                return (
                                    <Group lg={true} key={i} node={node}/>
                                )
                            }
                        })
                    }
                    </div>
                    <A href="#" variant="add" onClick={showMore}>Show more stories</A>
                </div>
            </div>
        </Layout>
    )
}

